<template>
    <div>
        <s-crud
            title="Fajas de salida de empaque"
            edit
            add
            @save="save($event)"
            :filter="filter"
            sorteable
            remove
            :config="config"
            search-input
            >
            <template v-slot="props">
                <v-container>
                    <v-row >
                        <v-col cols="3" lg="3" md="6" sm="12">
                            <s-text 
                                label="Descripcion"
                                v-model="props.item.PloDescription"
                                ref="txtPloDescription">
                            </s-text>
                        </v-col>
                        <v-col cols="3" lg="3" md="6" sm="12"> 
                            <s-select-definition                                 
                                :def=1154 label="Estado" 
                                v-model=props.item.PloStatus>
                            </s-select-definition>  
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:PloStatus="{row}">
                <v-chip x-small=""  :color="row.PloStatus == 1 ? 'success': 'error'" >
					{{ row.PloStatus == 1 ? 'Activo': 'Inactivo' }}
				</v-chip>
            </template>
        </s-crud>
    </div>
</template>


<script>

    import _sLinesPackingOut from '@/services/FrozenProduction/FrzLinesPackingOut.js'


    export default
    {
        data: () => {
            return{
                filter: {},
                config:{                    
                    service : _sLinesPackingOut,
                    headers: [                            
                        {text: "ID", value: "PloID",  align: "center", width: "10%", sortable: true},
                        {text: "Descripcion", value: "PloDescription",  align: "center", width: "80"},                        
                        {text: "Estado", value: "PloStatus",  align: "center", width: "30"},
                    ],
                    model:{
                        PloID: "ID",
                        PloStatus: "PloStatus",
                    },
                }
            }
        },
        methods: {
        
            save(item)
            {                  
                if(item.PloDescription == ""){
                    this.$fun.alert("Ingrese descripcion", "warning");
                    this.$refs.txtPloDescription.focus();
                    return;
                }        
                item.UsrID = this.$fun.getUserID();         
                item.save();
            },
           
        },
        created()
        {
            
        },
        
    }
</script>